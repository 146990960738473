@charset "UTF-8";
/* layout/_header.scss */

//
//  HEADER
// --------------------------------------------------

.site-header {
  @include boxSizing;
  z-index: 5;
  width: 100%;
  height: 50px;
  background-color: $white;
  box-shadow: 0px 3px 10px $shadow-black;

  .container {
    height: 100%;
    display: flex;
  }
}

.site-logo {
  @include boxSizing;
  cursor: pointer;
  width: 120px;
  height: 23px;
  margin: 14px 0 14px 70px;

  a {
    display: block;
    width: 120px;
    height: 23px;
    background-color: $white;
    background: url("../images/logo.png") left center no-repeat;
    background-size: 120px 23px;
    text-indent: -999px;
  }

  @include res(lg-down) {
    width: 103px;
    margin-left: 20px;

    a {
      width: 90px;
      background-size: 90px;
    }
  }
}

.site-gnb {
  margin-left: 50px;

  .gnb-items {
    display: flex;

    &.depth1 {
      column-gap: 25px;

      > li > a {
        padding-left: 27px;
        padding-right: 27px;
      }
      .active {
        > a {
          position: relative;
          font-weight: $font-weight-bold;
          color: $primary;

          &:after {
            content: "";
            display: block;
            width: 100%;
            height: 2px;
            background-color: $primary;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 5;
          }
        }

        .depth2 {
          display: flex;
        }
      }
    }
    &.depth2 {
      column-gap: 33px;
      position: absolute;
      top: 50px;
      left: 0;
      z-index: 5;
      width: 100%;
      min-height: 50px;
      padding: 0;
      padding-left: 240px;
      background-color: $gray-lightest;
      border-bottom: 1px solid $gray-lightest;
      box-shadow: inset 0px 3px 10px $shadow-black;
      display: none;

      &.is-show {
        display: flex !important;
      }
      .current {
        font-weight: $font-weight-bold;

        &:before {
          content: "";
          display: inline-block;
          width: 8px;
          height: 8px;
          background-color: $primary;
          border-radius: $border-radius-circle;
          margin-right: 8px;
          vertical-align: 2px;
        }
      }
    }
    a {
      display: inline-block;
      padding: 13px 0;
      font-size: $font-size-md;
      color: $black;

      &:hover,
      &:focus,
      &:active {
        font-weight: $font-weight-bold;
      }
    }
  }

  @include res(lg-down) {
    display: block;
    width: calc(100% - 398px);
    margin-left: 0;

    .gnb-items.depth1 {
      display: flex;
      overflow-x: auto;
      overflow-y: hidden;
      column-gap: 5px;
    }

    .gnb-items.depth1 > li {
      flex: 1 0 auto;
    }
    .gnb-items.depth1 > li > a {
      padding-left: 10px;
      padding-right: 10px;
    }
    .gnb-items.depth2 {
      padding-left: 123px;
      column-gap: 15px;
      overflow-x: auto;
      overflow-y: hidden;
    }

    .gnb-items a {
      min-height: 50px;
      font-size: $font-size-base;
    }
  }
}

.site-nav-action {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 58px;

  .action-item {
    padding: 0 12px;

    &.is-rbar {
      @include bar-before();
    }

    .custom-select {
      height: 18px;
      line-height: 18px;
      font-size: $font-size-md;
      padding-left: 0;
    }
  }

  .btn-login,
  .btn-logout {
    font-size: $font-size-md;
  }
  .btn-etas {
    min-width: 93px;
  }

  @include res(lg-down) {
    margin-right: 0;

    .action-item .custom-select,
    .btn-login,
    .btn-logout,
    .btn-etas {
      font-size: $font-size-base;
    }
  }
}
