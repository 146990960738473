@charset "UTF-8";
/* _status.scss */

//
//  운행현황
// --------------------------------------------------

// 운행 실적
.logbook-container {
  .table {
    display: none;

    &.is-show {
      display: table !important;
    }
  }
}
