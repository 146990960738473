@charset "UTF-8";
/* _tab.scss */

// Tab menu Style
.tabs {
  display: inline-flex;

  button,
  label {
    cursor: pointer;
  }

  &.tab-type1 {
    li,
    button {
      min-width: 260px;
      text-align: center;
      background-color: $gray-lightest;
      font-size: $font-size-md;
      color: $gray;
      padding: 18px 0;
      cursor: pointer;
      transition: 0.3s;

      &:first-child {
        border-radius: $border-radius-base 0 0 0;
      }
      &:last-child {
        border-radius: 0 $border-radius-base 0 0;
      }
      &.active {
        background-color: $white;
        color: $black;
        font-weight: $font-weight-medium;
      }
    }
    a {
      display: block;
      color: inherit;
    }
  }

  &.tab-type2 {
    display: flex;
    width: 100%;

    button {
      width: 50%;
      text-align: center;
      background-color: $gray-lightest;
      font-size: $font-size-base;
      color: $gray;
      padding: 10px 0;
      cursor: pointer;
      transition: 0.3s;

      &:first-child {
        border-radius: $border-radius-base 0 0 0;
      }
      &:last-child {
        border-radius: 0 $border-radius-base 0 0;
      }
      &.active {
        position: relative;
        background-color: $white;
        font-weight: $font-weight-bold;
        color: $primary;

        &:before {
          content: "";
          display: block;
          width: 100%;
          height: 2px;
          position: absolute;
          top: 0;
          background-color: $primary;
        }
      }
    }
    a {
      display: block;
      color: inherit;
    }
  }

  &.tab-type4 {
    display: flex;
    width: 100%;
    justify-content: center;

    li,
    button {
      position: relative;
      top: 1px;
      min-width: 16.67%;
      text-align: center;
      font-size: $font-size-md;
      color: $black;
      padding: 15px 0;
      border-bottom: 1px solid $gray-lighter;

      &:first-child {
        border-radius: $border-radius-base 0 0 0;
      }
      &:last-child {
        border-radius: 0 $border-radius-base 0 0;
      }
      &.active {
        position: relative;
        font-weight: $font-weight-bold;
        color: $primary;

        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 2px;
          position: absolute;
          bottom: -1px;
          background-color: $primary;
          z-index: 10;
        }
      }
    }
    a {
      display: block;
      color: inherit;
    }

    input {
      display: none;
    }
    input + label {
      display: block;
    }

    input ~ .tab {
      display: none;
    }
    #tabType41:checked ~ .tab.content1,
    #tabType42:checked ~ .tab.content2,
    #tab3:checked ~ .tab.content3,
    #tab4:checked ~ .tab.content4,
    #tab5:checked ~ .tab.content5 {
      display: block;
    }

    input + label {
      position: relative;
      top: 1px;
      min-width: 16.67%;
      text-align: center;
      font-size: $font-size-md;
      color: $black;
      padding: 15px 0;
      border-bottom: 1px solid $gray-lighter;
    }
    input:checked + label {
      font-weight: $font-weight-bold;
      color: $primary;
      position: relative;

      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        position: absolute;
        bottom: -1px;
        background-color: $primary;
      }
    }
    input ~ .tab {
      padding: 12px;
    }
  }
}

// Tab Content
.tabcontent {
  display: none;

  &.active {
    display: block;
  }
}
