@charset "UTF-8";
/* _vars.scss */

// Color system
$white: #fff !default;

$black: #111111 !default;
$black-light: #313139 !default;
$black-lighter: #42424b !default;

$gray: #888888 !default;
$gray-light: #dddddd !default;
$gray-lighter: #eaeaea !default;
$gray-lightest: #f8f8f8 !default;

$primary: #1782ff !default;
$primary-light: #81bbff !default;
$primary-lighter: #badaff !default;
$primary-bg: #f3f9ff !default;
$red: #ed3232 !default;
$warning: #ffd800 !default;
$warning-dark: #ffa700 !default;

$shadow-black: #0000000d !default;

// Typography

// font family
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Noto Sans KR",
  Malgun Gothic, Dotum, arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-base: $font-family-sans-serif !default;

// font size
$font-size-xs: 10px !default;
$font-size-sm: 12px !default;
$font-size-base: 14px !default;
$font-size-md: 16px !default;
$font-size-lg: 20px !default;
$font-size-xl: 24px !default;
$font-size-xxl: 28px !default;

// font weight
$font-weight-regular: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-bold: 700 !default;
$font-weight-black: 900 !default;
$font-weight-base: $font-weight-regular !default;

$line-height-base: 1.5 !default;

// border radius
$border-radius-xs: 2px !default;
$border-radius-sm: 4px !default;
$border-radius-md: 8px !default;
$border-radius-round: 50em !default;
$border-radius-circle: 50% !default;
$border-radius-base: $border-radius-sm !default;

// Breakpoints
$--xs: 576px !default;
$--sm: 768px !default;
$--md: 992px !default;
$--lg: 1200px !default;
$--ml: 1601px !default;
$--xl: 1920px !default;

$--breakpoints: (
  "xs-down": (
    max-width: $--xs - 1,
  ),
  "xs-up": (
    min-width: $--xs,
  ),
  "sm-down": (
    max-width: $--sm - 1,
  ),
  "sm-up": (
    min-width: $--sm,
  ),
  "md-down": (
    max-width: $--md - 1,
  ),
  "md-up": (
    min-width: $--md,
  ),
  "lg-down": (
    max-width: $--lg - 1,
  ),
  "lg-up": (
    min-width: $--lg,
  ),
  "ml-down": (
    max-width: $--ml - 1,
  ),
  "ml-up": (
    min-width: $--ml,
  ),
  "xl-down": (
    max-width: $--xl - 1,
  ),
  "xl-up": (
    min-width: $--xl,
  ),
);
