@charset "UTF-8";
/* _fonts.scss */

//
//   Web font Setting
// --------------------------------------------------

// Noto Sans KR
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Noto Sans KR Regular"), local("NotoSansKR-Regular"),
    url("../fonts/notoSansKR/notoSansKR-Regular.woff2") format("woff2"),
    url("../fonts/notoSansKR/notoSansKR-Regular.woff") format("woff");
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Noto Sans KR Medium"), local("NotoSansKR-Medium"),
    url("../fonts/notoSansKR/notoSansKR-Medium.woff2") format("woff2"),
    url("../fonts/notoSansKR/notoSansKR-Medium.woff") format("woff");
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Noto Sans KR Bold"), local("NotoSansKR-Bold"),
    url("../fonts/notoSansKR/notoSansKR-Bold.woff2") format("woff2"),
    url("../fonts/notoSansKR/notoSansKR-Bold.woff") format("woff");
}
