@charset "UTF-8";
/* _table.scss */

//
//  Table
// --------------------------------------------------

// 테이블 초기화
.table {
  width: 100%;
  border-collapse: separate;
  font-size: $font-size-base;

  th,
  td {
    padding: 0.75rem;
    vertical-align: middle;
  }
  thead th {
    vertical-align: middle;
    border-bottom: 1px solid $gray-lighter;
  }
  tbody th {
    border-top-width: 0;
  }

  tbody + tbody {
    border-top: 1px solid $gray-lighter;
  }
}

// 반응형 테이블
.table-responsive-md {
  @media (max-width: 1440.98px) {
    @include scrollbar();
    width: 100%;
    overflow-x: auto;

    .table {
      min-width: 1140px;
      table-layout: auto;
    }
  }
}

// 리스트 테이블
.table-list {
  table-layout: fixed;

  thead,
  tbody {
    th,
    td {
      text-align: center;
      color: $black;
      background-color: $white;

      .cell-radio,
      .cell-checkbox {
        .custom-control-wrap.custom-control-inline .custom-control {
          vertical-align: middle;
        }
        .form-item-wrap {
          justify-content: center;
        }
      }
    }
  }

  thead {
    th,
    td {
      padding: 6px 7px;
      font-size: $font-size-base;
      font-weight: $font-weight-regular;
      color: $gray;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  tbody {
    th,
    td {
      padding: 7px;
      font-size: $font-size-base;

      .link {
        @include text-cut();
        display: block;
        width: 100%;
      }
    }
    tr:hover {
      th,
      td {
        background-color: $gray-lighter;
      }
    }
    .cell:not(.cell-select) {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
    }
    .cell.cell-select {
      .custom-select.nice-select {
        .current {
          @include text-cut();
          width: 100%;
          display: block;
        }
      }
    }
    .none-hover {
      &:hover {
        th,
        td {
          background-color: $white;
        }
      }
    }
  }
}

.table-list-pop {
  table-layout: fixed;

  thead,
  tbody {
    th {
      background-color: $gray-lighter;
      text-align: center;
    }
    td {
      cursor:pointer;
      text-align: center;
      color: $black;
      background-color: $white;

      .cell-radio,
      .cell-checkbox {
        .custom-control-wrap.custom-control-inline .custom-control {
          vertical-align: middle;
        }
        .form-item-wrap {
          justify-content: center;
        }
      }
    }
  }

  thead {
    th,
    td {
      padding: 6px 7px;
      font-size: $font-size-base;
      font-weight: $font-weight-regular;
      color: $gray;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  tbody {
    th,
    td {
      padding: 7px;
      font-size: $font-size-base;

      .link {
        @include text-cut();
        display: block;
        width: 100%;
      }
    }
    .cell:not(.cell-select) {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
    }
    .cell.cell-select {
      .custom-select.nice-select {
        .current {
          @include text-cut();
          width: 100%;
          display: block;
        }
      }
    }
    .none-hover {
      &:hover {
        th,
        td {
          background-color: $white;
        }
      }
    }
  }
}
// 리스트 테이블
.table-list.lg {
  table-layout: fixed;

  tbody {
    th,
    td {
      padding: 14px 5px;
    }
  }
}

// 리스트 테이블 (y축 border)
.table-list.y-line {
  thead {
    th,
    td {
      padding: 7px;
      border-bottom: 0;
    }
  }
  tbody {
    th,
    td {
      border-bottom: 1px solid $gray-lighter;
    }
  }
}

// 리스트 테이블 (x,y축 border)
.table-list.xy-line {
  tbody {
    th,
    td {
      position: relative;
      border-bottom: 1px solid $gray-lighter;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
        width: 1px;
        height: 20px;
        background-color: $gray-lighter;
      }

      &:first-child:before {
        display: none;
      }
    }
  }
}

// 리스트 테이블 thead-gray-lighter
.table-list.thead-gray-lighter {
  thead {
    th,
    td {
      position: relative;
      background-color: $gray-lighter;
      background-color: $gray-lightest;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
        width: 1px;
        height: 20px;
        background-color: $gray-lighter;
      }

      &:first-child:before {
        display: none;
      }
    }
  }
  tbody {
    th {
      font-weight: $font-weight-regular;
      background-color: $gray-lightest;

      &.row-type {
        color: $gray;

        &:before {
          display: none;
        }

        + td {
          &:before {
            display: none;
          }
        }
      }
      &[rowspan] {
        border-right: 1px solid $gray-lighter;

        &:before {
          display: none;
        }
      }
    }
  }
}

// 리스트 테이블 thead-primary-bg
.table-list.thead-primary-bg {
  thead {
    th,
    td {
      position: relative;
      padding: 14px 12px;
      background-color: $primary-bg;
      color: $primary;

      &:before {
        display: none;
      }

      &:first-child:before {
        display: none;
      }
    }
    th {
      border-bottom: 0;

      &:first-child {
        font-weight: $font-weight-bold;
      }
    }
  }
  tbody {
    th,
    td {
      padding: 9px 12px;
    }
    th {
      font-weight: $font-weight-regular;
    }
    tr:hover {
      th,
      td {
        background-color: transparent;
      }
    }
  }
}

// 뷰, 폼 테이블
.table.table-view {
  tbody {
    th,
    td {
      padding: 10px 5px;
      color: $black;

      .form-control {
        background-color: $gray-lightest;
        border-color: $gray-lightest;
      }
      .form-control.form-datepicker {
        background: $gray-lightest url("../images/icon/icon_calendar.svg")
          calc(100% - 14px) center no-repeat;
      }
    }

    th {
      width: 92px;
      padding-left: 0;
      color: $black;
      text-align: left;
      font-weight: $font-weight-medium;
    }

    td {
      .form-data-text + .form-data-text {
        margin-top: 8px;
      }
      .btn {
        padding-left: 24px;
        padding-right: 24px;
      }

      .form-item-wrap.col-7.col-xl-4,
      .form-item-wrap.col-12.col-xl-4 {
        min-width: 240px;
      }
      .form-item-wrap.col-12.col-xl-6 {
        min-width: 342px;

        .btn {
          width: 90px;
        }
      }
      .form-item-wrap.col-12.col-xl-8 {
        min-width: 342px;

        .btn {
          width: 160px;
        }
      }
    }
  }
  tbody + tbody {
    padding: 4px 0;
    border-top: 1px solid $gray-lighter;

    tr:first-child {
      th,
      td {
        border-top: 1px solid $gray-lighter;
      }
    }
  }
}
.table.table-view.sm {
  tbody {
    th,
    td {
      padding: 7px 5px;

      .form-data-text {
        line-height: unset;
      }
    }
    tr:first-child {
      th,
      td {
        padding-top: 20px;
      }
    }
    tr:last-child {
      th,
      td {
        padding-bottom: 20px;
      }
    }
  }
}
.table.table-view.lg {
  tbody {
    th,
    td {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
}

// table-empty (전체 데이터 테이블 내역 없을 경우)
.table-empty {
  padding: 100px 0;
  text-align: center;
}

// 선택 시, 추가 클래스명
.is-checked {
  th,
  td {
    background-color: $primary-bg !important;
  }
}
