@charset "UTF-8";
/* _forms.scss */

//
//  Form 관련 Text
// --------------------------------------------------

// 폼 항목 - 필수값 표시
.required-data {
  position: relative;
  display: inline-block;
  color: $red;
  text-indent: -9999px;

  &:before {
    content: "*";
    position: absolute;
    left: 4px;
    top: 0;
    display: inline-block;
    color: $red;
    text-indent: 0;
  }
}

// form 설명글
.form-desc {
  font-size: $font-size-base;
  margin-top: 20px;
  color: $gray;

  &.alert {
    color: $red;
  }
}

// 폼 에러 메시지
.form-error-check {
  font-size: $font-size-base;
  color: $red;
}

// form data 텍스트로 표출
.form-data-text {
  display: inline-block;
  max-width: 100%;
  line-height: 32px;

  &.lh15 {
    line-height: 1.5;
  }
  &.group {
    line-height: 1.22;
  }

  + .btn {
    margin-left: 15px;
    padding-left: 24px;
    padding-right: 24px;
  }
}

//
//  Form Icon Button
// --------------------------------------------------

.form-icon {
  &.eye-show {
    display: block;
    width: 100%;
    height: 100%;
    background: url("../images/icon/icon_pw_show.svg") no-repeat;
  }
  &.eye-hide {
    display: block;
    width: 100%;
    height: 100%;
    background: url("../images/icon/icon_pw_hide.svg") no-repeat;
  }
}

//
//  Form elements
// --------------------------------------------------

// 폼 항목 공통 스타일 (input, select, textarea)
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.9334rem + 2px);
  padding: 0.4667rem 1rem;
  height: 40px;
  line-height: 40px;
  font-size: $font-size-base;
  font-weight: $font-weight-regular;
  line-height: 1.5;
  color: $black;
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid $gray-lighter;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-sizing: border-box;
  -webkit-appearance: none;
  border-radius: $border-radius-base;

  // input sizes
  &.xsmall {
    height: 24px;
    line-height: 24px;
    border-radius: $border-radius-xs;
    padding-left: 10px;
    padding-right: 10px;
  }
  &.small {
    height: 32px;
    line-height: 32px;
  }
  &.large {
    height: 48px;
    line-height: 16px;
  }
  &.xlarge {
    height: 66px;
    line-height: 64px;
  }

  &.bg-gray {
    background-color: $gray-lightest;
    border-color: $gray-lightest;
  }
}

input[type="text"].form-control:disabled,
input[type="number"].form-control:disabled,
textarea.form-control:disabled {
  background: $gray-lighter;
  border: 1px solid $gray-light;
  color: $gray;
}

// 폼 항목 - datapicker
.form-control.form-datepicker {
  background: $white url("../images/icon/icon_calendar.svg") calc(100% - 14px)
    center no-repeat;
  background-size: 24px 24px;
}

// 폼 항목 - textarea
textarea.form-control {
  @include scrollbar();
  min-height: 105px;
  line-height: 1.22;
  padding: 1.0625rem 1.125rem;

  &.lg {
    min-height: 300px;
  }
}

//
//  Select
// --------------------------------------------------
.custom-select {
  @include boxSizing;
  display: inline-block;
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0 2.3rem 0 1rem;
  font-weight: $font-weight-regular;
  color: $black;
  border: 1px solid $gray-light;
  appearance: none;

  &.nice-select {
    background-color: $white;
    border-color: $gray-light;

    &.bg-gray {
      background-color: $gray-lightest;
      border-color: $gray-lightest;
    }
    .list {
      min-width: 100%;
      background-color: $white;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
      border: 1px solid $gray-light;
      border-radius: $border-radius-base;
    }
    .current {
      @include text-cut();
      display: block;
      width: 100%;
    }
  }

  &.nice-select:active,
  &.nice-select.open,
  &.nice-select:focus {
    border-color: $primary;

    &:after {
      border-color: $primary;
    }
    .current {
      color: $primary;
    }
  }

  &.no-outlined {
    border: 0;
  }
}

//
//  Checkboxes / Radios
// --------------------------------------------------

// 공통 체크박스, 라디오
.custom-control {
  position: relative;
  min-height: 1.5rem;

  // custom-control-input
  &-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 20px;
    height: 20px;
    opacity: 0;
    margin: 0;
  }

  // custom-control-label
  &-label {
    position: relative;
    display: block;
    min-width: 20px;
    min-height: 20px;
    padding-left: 20px;
    margin-bottom: 0;
    font-size: $font-size-base;
    color: $black;
    cursor: pointer;

    // Background-color and (when enabled) gradient
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 20px;
      height: 20px;
      box-sizing: border-box;
      pointer-events: none;
      background-color: $white;
      border: 1px solid $primary;
    }

    // Foreground (icon)
    &::after {
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      display: block;
      width: 20px;
      height: 20px;
      content: "";
      background: no-repeat 50% / 50% 50%;
    }

    span {
      display: inline-block;
      margin-left: 10px;
      line-height: 18px;
      vertical-align: middle;
      word-break: keep-all;
    }
  }
}

// 체크박스 스타일
.custom-checkbox {
  display: inline-block;
  min-width: 18px;
  min-height: 18px;
  line-height: 18px;
  text-align: center;

  // .custom-control-label {
  //   color: $gray;
  // }
  .custom-control-label::before {
    border-color: $gray-light;
    border-radius: $border-radius-xs;
  }
  .custom-control-label::after {
    background-size: 11px 9px;
  }

  .custom-control-input:checked ~ .custom-control-label {
    color: $black;

    &::before {
      border-color: $primary;
      background-color: $primary;
    }
    &::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.314' height='8.485' viewBox='0 0 11.314 8.485'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23ffffff;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M10.586,13.414,7.757,10.586,6.343,12l4.243,4.243,7.071-7.071L16.243,7.757Z' transform='translate(-6.343 -7.757)'/%3E%3C/svg%3E");
    }
  }

  // size
  &.xsmall {
    .custom-control-label {
      min-width: 14px;
      min-height: 14px;
      font-size: $font-size-sm;
      padding-left: 14px;
      padding-right: 0;
    }

    .custom-control-label::before,
    .custom-control-label::after {
      width: 14px;
      height: 14px;
    }
    .custom-control-label span {
      margin-left: 5px;
    }
  }
  &.small {
    .custom-control-label {
      min-width: 17px;
      min-height: 17px;
      font-size: $font-size-sm;
      padding-left: 17px;
      padding-right: 0;
    }

    .custom-control-label::before,
    .custom-control-label::after {
      width: 17px;
      height: 17px;
    }
    .custom-control-label span {
      margin-left: 5px;
    }
  }

  // 오른쪽에서 왼쪽방향
  &.is-rtl {
    .custom-control-input {
      left: unset;
      right: 0;
    }
    .custom-control-label {
      text-align: left;
      padding-left: 0;
      padding-right: 20px;
    }
    .custom-control-label::before,
    .custom-control-label::after {
      left: unset;
      right: 0;
    }
    .custom-control-label span {
      margin-left: 0;
      margin-right: 5px;
    }

    &.xsmall {
      .custom-control-input {
        width: 14px;
        height: 14px;
      }
      .custom-control-label {
        padding-left: 0;
        padding-right: 14px;
      }
    }
    &.small {
      .custom-control-input {
        width: 17px;
        height: 17px;
      }
      .custom-control-label {
        padding-left: 0;
        padding-right: 17px;
      }
    }
  }

  // color
  &.color-yellow {
    .custom-control-label::before {
      background-color: transparent;
      border-color: $warning;
      border-radius: 0;
    }
    .custom-control-label::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.314' height='8.485' viewBox='0 0 11.314 8.485'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23ffd800;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M10.586,13.414,7.757,10.586,6.343,12l4.243,4.243,7.071-7.071L16.243,7.757Z' transform='translate(-6.343 -7.757)'/%3E%3C/svg%3E");
      background-size: 9px 6px;
    }
    .custom-control-label,
    .custom-control-input:checked ~ .custom-control-label {
      color: $white;
    }
    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: $warning;
      border-color: $warning;
    }
    .custom-control-input:checked ~ .custom-control-label::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.314' height='8.485' viewBox='0 0 11.314 8.485'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23ffffff;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M10.586,13.414,7.757,10.586,6.343,12l4.243,4.243,7.071-7.071L16.243,7.757Z' transform='translate(-6.343 -7.757)'/%3E%3C/svg%3E");
      background-size: 9px 6px;
    }
  }
}

// 라디오 스타일
.custom-radio {
  display: inline-block;
  min-width: 20px;
  min-height: 20px;
  line-height: 20px;
  text-align: center;

  .custom-control-label {
    color: $black;
  }
  .custom-control-label::before {
    border-width: 2px;
    border-radius: $border-radius-circle;
    border-color: $gray-light;
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::before {
      border-color: $primary;
    }

    &::after {
      @include boxSizing();
      content: "";
      position: absolute;
      left: 5px;
      display: block;
      width: 10px;
      height: 10px;
      background: no-repeat 50% / 50% 50%;
      padding: 5px;
      background: $primary;
      border-radius: $border-radius-circle;
    }
  }

  .custom-control-input:disabled {
    &:checked ~ .custom-control-label::before {
      background-color: $white;
    }
  }

  &.small {
    .custom-control-input {
      width: 18px;
      height: 18px;
    }
    .custom-control-label {
      font-size: $font-size-sm;
      padding-left: 18px;
      min-width: 18px;
      min-height: 18px;
    }
    .custom-control-label::before,
    .custom-control-label::after {
      width: 18px;
      height: 18px;
    }
    .custom-control-input:checked ~ .custom-control-label::after {
      width: 6px;
      height: 6px;
      padding: 4px;
    }
  }
}

// 공통 체크박스, 라디오 - 정렬
.custom-control-wrap.custom-control-block {
  .custom-control {
    display: block;
    text-align: left;

    + .custom-control {
      margin-top: 8px;
    }
  }
}
.custom-control-wrap.custom-control-inline {
  text-align: center;

  .custom-control {
    display: inline-block;
  }
  .custom-control + .custom-control {
    margin-left: 10px;
  }
  .custom-control.small + .custom-control.small {
    margin-left: 5px;
  }
}

//
//  Radio Button group
// --------------------------------------------------
.radio-group {
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
  font-size: 0;

  .radio-button + .radio-button {
    margin-left: 12px;
  }

  .radio-button,
  .radio-button__inner {
    position: relative;
    display: inline-block;
    outline: none;
  }
  .radio-button__inner {
    line-height: 1;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    margin: 0;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    padding: 13px 24px;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    border-radius: $border-radius-sm;
  }
  .radio-button__orig-radio {
    opacity: 0;
    outline: none;
    position: absolute;
    z-index: -1;
  }

  &.small {
    .radio-button + .radio-button {
      margin-left: 5px;
    }
    .radio-button__inner {
      padding: 8px;
      font-size: $font-size-sm;
      font-weight: $font-weight-regular;
    }
  }

  &.type1 {
    .radio-button__inner {
      background-color: $primary-bg;
      color: $primary;
    }
    .radio-button__orig-radio {
      &:checked {
        + .radio-button__inner {
          background-color: $primary;
          color: $white;
        }
      }
    }
  }
  &.type2 {
    .radio-button__inner {
      border: 1px solid $gray-light;
      background-color: $white;
      color: $gray;
    }
    .radio-button__orig-radio {
      &:checked {
        + .radio-button__inner {
          background-color: $primary-bg;
          border: 1px solid $primary-lighter;
          color: $primary;
        }
      }
    }
  }
  &.type3 {
    .radio-button__inner {
      border: 1px solid $gray-light;
      background-color: $white;
      color: $gray;
      border-radius: 50em;
    }
    .radio-button__orig-radio {
      &:checked {
        + .radio-button__inner {
          background-color: $primary;
          border: 1px solid $primary;
          color: $white;
        }
      }
    }
  }
}

// multi-checkbox - 다중체크박스 그룹
.multi-checkbox {
  padding-top: 32px;
  margin-top: 12px;
  border-top: 1px solid $gray-lighter;
}
.multi-checkbox__head {
  padding-bottom: 20px;
  border-bottom: 1px solid $gray-lighter;
  display: flex;

  .custom-control-wrap {
    display: flex;
    align-items: center;
    font-size: $font-size-base;
  }
  .custom-checkbox {
    display: flex;
    padding: 9px 12px;
    margin-right: 12px;
    border: 1px solid $primary;
    border-radius: $border-radius-sm;

    .custom-control-label {
      color: $primary;

      &::before {
        border-color: $primary;
      }
      span {
        margin-left: 6px;
      }
    }
    .custom-control-input:checked ~ .custom-control-label {
      color: $primary;
    }
  }

  .list-btns {
    margin-left: auto;

    .btn {
      min-width: 100px;
    }
  }
}
.multi-checkbox__body {
  border-bottom: 1px solid $gray-lighter;

  .custom-control-wrap {
    &.custom-control-inline {
      padding: 20px 38px;

      .custom-control + .custom-control {
        margin-left: 0;
      }
    }
    &.grid-3 {
      display: flex;
      flex-wrap: wrap;
      row-gap: 24px;

      .custom-checkbox {
        flex: 0 1 33.333%;
      }
      .custom-control-label {
        text-align: left;
      }
    }
  }
}

//
//  file attach
// --------------------------------------------------

// 첨부파일 요소
.attach-area {
  display: flex;
  align-items: center;

  // 첨부파일 - 버튼형
  input[type="file"].custom-input-file {
    display: none;
    cursor: pointer;
  }
  .attach-file-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 18px;
    font-size: $font-size-base;
    color: $gray;
    text-decoration: underline;

    + .attach-file-item {
      margin-top: 5px;
    }
  }
  .attach-file-delete {
    width: 18px;
    height: 18px;
    margin-left: 7px;
    background: url("../images/icon/icon_close_gray.svg") center center
      no-repeat;
    background-size: 18px 18px;
    text-indent: -9999px;
    overflow: hidden;
  }

  .file-button + .attach-file-lists {
    margin-left: 20px;
  }
}

//
//  Form Group 구조
// --------------------------------------------------

// form 항목들 실영역 부모요소 (최종정리)
.form-label {
  width: auto;
  font-size: $font-size-md;
  font-size: 16px;
  font-weight: 500;
  margin-right: 10px;
}

.form-item-wrap {
  font-size: $font-size-md;
  max-width: 100%;
  display: flex;
  align-items: center;

  // Align Classname
  &.is-col {
    flex-direction: column;
    align-items: flex-start;
  }
  &.is-row {
    flex-direction: row;
    margin-right: 10px;

    .form-label {
      flex: 1 0 auto;
      min-width: unset;
    }
    .form-item {
      flex: 0 1 auto;
    }
  }

  &.align-center {
    justify-content: center;
  }

  // Size Classname
  &.w-730 {
    width: 730px;
  }
  &.w-390 {
    width: 390px;
  }
  &.w-350 {
    width: 350px;
  }
  &.w-310 {
    width: 310px;
  }
  &.w-270 {
    width: 270px;
  }
  &.w-240 {
    width: 240px;
  }
  &.w-200 {
    width: 200px;
  }
  &.w-140 {
    width: 140px;
  }
  &.w-74 {
    width: 74px;
  }

  // Button Classname
  &.form-item-button {
    align-items: flex-end;
  }
}

.form-item {
  flex: 1 1 auto;
  width: 100%;

  // Input error
  &.has-error {
    .form-control {
      border-color: $red;
      background-color: $white;
    }
  }

  // Form Icons
  &.has-icon-left,
  &.has-icon-right {
    position: relative;

    .form-icon {
      position: absolute;
      top: 50%;
      right: 14px;
      transform: translateY(-50%);
      z-index: 1;
      display: block;
      width: 24px;
      height: 24px;
    }
  }
}

// input 과 버튼 or 텍스트를 같이 쓰는 경우
// 해당 요소로 묶어서 사용
.form-item-inn {
  display: flex;
  align-items: center;

  .form-control {
    flex: 1;
  }
  .form-control + .btn {
    margin-left: 12px;
  }
  .unit {
    display: block;
    padding: 0 8px;
    flex: 0 0 auto;
  }

  &.is-fixed {
    .form-control.form-datepicker {
      max-width: 180px;
    }
  }
}

// form태그 내 실입력항목 그룹영역
.form-group {
  display: flex;

  &.is-wrap {
    flex-wrap: wrap;

    .form-item-wrap {
      margin-right: 18px;

      &:last-child {
        margin-left: 0;
      }
    }
  }
  &.is-col {
    flex-direction: column;

    .form-item-wrap {
      margin-bottom: 10px;
    }
  }

  &.is-row {
    flex-direction: row;
    row-gap: 18px;

    .form-item-wrap {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
