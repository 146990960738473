@charset "UTF-8";
/* _common.scss */

//
//  Help Style
// --------------------------------------------------

// only screen reader
.disabled-time-list {
  background-color: #f6f6f6 !important;
}
.screen-reader,
.screen-reader-text {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
}

// Margin and Padding
@for $i from 0 through 6 {
  .mt-#{$i*5} {
    margin-top: $i * 5px !important;
  }
  .mr-#{$i*5} {
    margin-right: $i * 5px !important;
  }
  .mb-#{$i*5} {
    margin-bottom: $i * 5px !important;
  }
  .ml-#{$i*5} {
    margin-left: $i * 5px !important;
  }

  .pt-#{$i*5} {
    padding-top: $i * 5px !important;
  }
  .pr-#{$i*5} {
    padding-right: $i * 5px !important;
  }
  .pb-#{$i*5} {
    padding-bottom: $i * 5px !important;
  }
  .pl-#{$i*5} {
    padding-left: $i * 5px !important;
  }
}

@for $i from 0 through 100 {
  .wd-#{$i} {
    width: $i * 1% !important;
  }
}

.ml-auto {
  margin-left: auto !important;
}

//
//  Text Style
// --------------------------------------------------

// text align
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}

// text color
.text-primary {
  color: $primary !important;
}
.text-danger {
  color: $red !important;
}
.text-warning {
  color: $warning !important;
}
.text-black {
  color: $black !important;
}
.text-gray {
  color: $gray !important;
}

// text underline
.text-under {
  text-decoration: underline;
}

// Infomation Text
.text-info {
  &.is-flex {
    display: flex;
    align-items: flex-start;
    margin-top: 12px;
  }

  .subj {
    flex: 0 0 auto;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    margin-right: 12px;
  }
  .desc {
    flex: 1 1 auto;
    font-size: $font-size-base;
    color: $gray;
  }
}

// text bold
strong,
.strong {
  font-weight: $font-weight-bold;
}

// badge Style
.badge {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.42;
  padding: 4px 16px;
  border-radius: $border-radius-round;
  font-size: $font-size-base;
  font-weight: $font-weight-regular;
  text-transform: uppercase;

  &.small {
    padding: 3px 10px;
    font-size: $font-size-xs;
  }

  &.status-on {
    background-color: $primary;
    color: $white;
  }
  &.status-off {
    background-color: $gray-light;
    color: $gray;
  }
}

// tootip
.ui-tooltip.ui-widget-shadow {
  box-shadow: none;
}
.ui-tooltip {
  padding: 10px 20px;
  color: $white;
  border-radius: 20px;
  font-family: $font-family-base !important;
}
.ui-tooltip-content {
  line-height: 1.614;
}
.arrow {
  width: 84px;
  height: 16px;
  overflow: hidden;
  position: absolute;
  left: 50%;
  margin-left: -42px;
  bottom: -16px;
}
.arrow.top {
  top: -16px;
  bottom: auto;
}
.arrow.left {
  left: 20%;
}
.arrow:after {
  content: "";
  position: absolute;
  left: 33px;
  top: -8px;
  width: 12px;
  height: 12px;
  background: $white;
  border: 1px solid #c5c5c5;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.arrow.top:after {
  bottom: -20px;
  top: auto;
}

// card Style
.card {
  background: $white;
  border: 1px solid $gray-lighter;
  border-radius: $border-radius-base;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 32px;

  + .card {
    margin-top: 40px;
  }

  // card-title
  &-title {
    font-size: $font-size-lg;
    color: $black;
  }
  // card-subtitle
  &-subtitle {
    margin-top: 12px;
    font-size: $font-size-base;
    color: $gray;
  }
}

// 링크 메뉴 리스트
.link-menu-list {
  display: inline-flex;

  li {
    &:not(:first-child) {
      &:before {
        content: "";
        display: inline-block;
        width: 1px;
        height: 12px;
        margin: 0 10px;
        vertical-align: middle;
        background-color: $gray;
      }
    }
    a {
      font-size: $font-size-base;
      font-weight: $font-weight-medium;
      color: $gray;
    }
  }
}

// 영역 show/hide
.is-show {
  display: block !important;

  &.table {
    display: table !important;
  }
  &.row,
  &.is-row,
  &.is-col {
    display: flex !important;
  }
}
.is-hide {
  display: none !important;
}

.icon-size {
  width:16px; height:16px;
}

.float-l {
  float:left;
}