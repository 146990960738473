@charset "UTF-8";
/* _service.scss */

//
//  고객센터
// --------------------------------------------------

// 일대일문의
.write-inquiry {
  .table.table-view tbody th {
    &:not(:first-child) {
      padding-left: 35px;
    }
  }
}

// 마이페이지
.mgnt-mypage {
  padding: 16px 0;
  margin-top: 32px;
  border-top: 1px solid $gray-lighter;
  border-bottom: 1px solid $gray-lighter;

  .custom-control-wrap.custom-control-inline .custom-control + .custom-control {
    margin-left: 70px;
  }
}

.height-percent {
  height:100%;
}

.list-card__body {
  .mgnt-mypage {
    padding: 0;
    margin-top: 0;
    border-top: 0;
    border-bottom: 0;

    .table-wrap {
      margin-top: 13px;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}
