@charset "utf-8";
/* _accordions.scss */

.accordion {
  input:checked ~,
  &[open] {
    & .accordion-header > {
      .icon:first-child {
        transform: rotate(90deg);
      }
    }

    & .accordion-body {
      max-height: none;
    }
  }

  .accordion-header {
    display: block;
    padding: 5px 10px;

    .icon {
      transition: transform 0.25s;
    }
  }

  .accordion-body {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.25s;
  }
}

// 아코디언 스타일
.accordion {
  &.type1 {
    .accordion-header {
      position: relative;
      display: flex;
      align-items: center;
      min-height: 50px;
      padding: 11px 15px;
      padding-right: 38px;
      background-color: $gray-lightest;
      font-size: $font-size-base;
      font-weight: $font-weight-medium;
      color: $black;

      &:after {
        content: "";
        display: block;
        position: absolute;
        right: 14px;
        top: 50%;
        transform: translateY(-50%) rotate(0deg);
        z-index: 5;
        width: 24px;
        height: 24px;
        background: url("data:image/svg+xml,%3Csvg id='ic_arrow_down_lg' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath id='arrow_path_13' data-name='arrow_path_13' d='M0 0H24V24H0Z' fill='none'/%3E%3Cpath id='arrow_path_14' data-name='arrow_path_14' d='M12 15 7.757 10.757 9.172 9.343 12 12.172l2.828-2.829 1.415 1.414Z' fill='%23dddddd'/%3E%3C/svg%3E")
          center center no-repeat;
        background-size: 24px 24px;
        transition: all 0.2s ease-in;
      }
      .num {
        flex: 0 0 auto;
        margin-left: 8px;
        color: $primary;
      }
    }
    input:checked ~,
    &[open] {
      & .accordion-header {
        background-color: $primary;
        font-weight: $font-weight-bold;
        color: $white;

        &:after {
          background: url("data:image/svg+xml,%3Csvg id='ic_arrow_down_lg' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath id='arrow_path_13' data-name='arrow_path_13' d='M0 0H24V24H0Z' fill='none'/%3E%3Cpath id='arrow_path_14' data-name='arrow_path_14' d='M12 15 7.757 10.757 9.172 9.343 12 12.172l2.828-2.829 1.415 1.414Z' fill='%23ffffff'/%3E%3C/svg%3E")
            center center no-repeat;
          background-size: 24px 24px;
          transform: translateY(-50%) rotate(-180deg);
          transform-origin: center center;
        }
        .num {
          color: $warning;
        }
      }
    }
  }
}
.accordion + .accordion {
  margin-top: 8px;
}
