@charset "UTF-8";
/* _popup.scss */

.popup {
  // popup-layer
  &-layer {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.2s;
    padding: 60px 0 20px 0;

    &.on {
      z-index: 999;
      opacity: 1;
    }
    .bg-dim {
      background: rgba(22, 22, 55, 0.5);
      height: 100%;
      position: fixed;
      z-index: 400;
      width: 100%;
      top: 0;
      left: 0;
    }
  }

  // popup-wrap
  &-wrap {
    position: absolute;
    left: 50%;
    z-index: 500;
    max-width: 895px;
    width: calc(100% - 40px);
    margin: 0 auto;
    background-color: $white;
    transform: translate(-50%, 0);
    border-radius: $border-radius-md;
  }

  &-wrap2 {
    position: absolute;
    left: 50%;
    z-index: 500;
    max-width: 1050px;
    width: calc(100% - 40px);
    margin: 0 auto;
    background-color: #fff;
    transform: translate(-50%, 0);
    border-radius: $border-radius-md;
  }

  // popup-header
  &-header {
    position: relative;
    text-align: left;
    padding: 40px 40px 20px 40px;
  }

  // popup-title
  &-title {
    font-size: $font-size-lg;
    line-height: 1.45;
    font-weight: $font-weight-medium;
    color: $black;
  }

  // popup-title
  &-sub-title {
    font-size: $font-size-md;
    line-height: 1.45;
    font-weight: $font-weight-medium;
    color: $black;
  }

  &-sub-small-title {
    font-size: $font-size-base;
    line-height: 1.45;
    font-weight: $font-weight-medium;
    color: $black;
  }

  // popup-close
  &-close {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 10px;
  }

  // popup-body
  &-body {
    max-height: 513px;
    padding: 0 40px 40px 40px;
    text-align: left;
    overflow-y: auto;
    @include scrollbar();

    .popup-title {
      margin-bottom: 20px;
    }

    .list-card__footer .list-footer__btns .btn {
      min-width: 74px;
    }
    .table-responsive-md {
      @include scrollbar();
      width: 100%;
      overflow-x: auto;

      .table {
        min-width: 1140px;
        table-layout: auto;
      }
    }
  }

  &-tb-list {
    .datev td{
      border: 1px solid #eaeaea;
    }
    .chk-orange {
      background-color:#ffd800;
    }
    .chk-blue {
      background-color:#1782ff;
    }
  }

  // popup-content
  &-content {
    line-height: 1.5;

    .popup-text {
      font-size: $font-size-base;
    }
  }
  &-mt-type1 {
    float: right;
  }
  &-group-type1, &-group-type2 {
    display: inline-flex;
    width: 100%;
  }
  &-mr-type1 {
    margin-right: 20px;
  }
  &-mr-type2 {
    margin-right: 20px;
  }
  &-pfl {
    //float: left;
  }
  &-pfr {
    float: right;
  }
  &-ph-30 {
    height: 40px;
    line-height: 40px;
  }
  @media only screen and (max-width: 900px) {
    &-mt-type1 {
      all: unset;
      margin-top: 20px;
      float: right;
    }
    &-mr-type1 {
      all: unset;
      width: auto;
    }
    &-group-type1, &-group-type2 {
      all: unset;
      display:inline-block;
      width: 100%;
    }
  }

  &-pt-10 {
    padding-top:10px;
  }
  &-pt-21 {
    padding-top:21px;
  }
  &-pr-13 {
    padding-right:13px;
  }
  &-w-74 {
    width: 74px;
  }
  &-w-90 {
    width: 90px;
  }
  &-w-150 {
    width: 150px;
  }
  &-allioc{
    .pr-10 {
      padding-right:10px;
    }
    .fl {
      float:left;
    }
    .fr {
      float:right;
    }
    .pinw {
      width:145px;
    }
    .ico1 {
      display:inline-block;
      width:16px;
      height:16px;
      background:#ffd800 0% 0% no-repeat padding-box;
      opacity: 1;
      float:left;
    }
    .ico2 {
      display:inline-block;
      width:16px;
      height:16px;
      background:#1782ff 0% 0% no-repeat padding-box;
      opacity: 1;
      float:left;
    }
    .all-txt {
      font-size:12px;
    }
  }

  // etc custormazing
  &-cont-cust {
    line-height: 2;

    .f1-lh {
      line-height: 1.5;
    }

    .tit_font {
      font-size: 16px;
    }
    .btn-submit {
      width:280px;
    }
    .inw-280 {
      width:280px;
      display:inline;
    }
    .pt-10 {
      padding-top: 10px;
    }
    .pt-20 {
      padding-top: 20px;
    }
    .pt-zero {
      padding: 0;
    }

    .tit_font_b {
      font-weight: bold;
      font-size: 25px;
    }

    .location-popup-map {
      display:flex;
      width:880px;
      height:400px;
    }
  }

  &-cont-cust-align {
    text-align:center;
  }

  &-cont-map {
    .location-popup-map {
      display:flex;
      width:800px;
      height:400px;
    }
  }
}
