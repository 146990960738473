@charset "UTF-8";
/* _grid.scss */

//
//  Grid
// --------------------------------------------------

//----- Grid System
$grid-spacing: 10px;

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -(#{$grid-spacing});
  margin-right: -(#{$grid-spacing});

  & + & {
    margin-top: $grid-spacing;
  }

  @include res(lg-down) {
    margin-left: 0;
    margin-right: 0;
  }
}

[class^="col-"] {
  @include boxSizing;
  -ms-flex: 1;
  flex: 1;
  max-width: 100%;
  margin-left: $grid-spacing;
  margin-right: $grid-spacing;

  @include res(lg-down) {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 20px;
  }
}
//----- Columns (Automatic)
.col-1 {
  width: (100% / 12);
  flex: 0 1 (100% / 12);
}
.col-2 {
  width: (100% / 6);
  flex: 0 1 (100% / 6);
}
.col-3 {
  width: (100% / 4);
  flex: 0 1 (100% / 4);
}
.col-4 {
  width: (100% / 3);
  flex: 0 1 (100% / 3);
}
.col-5 {
  width: (100% / 2.4);
  flex: 0 1 (100% / 2.4);
}
.col-6 {
  width: (100% / 2);
  flex: 0 1 (100% / 2);
}
.col-7 {
  width: (100% / 1.714285714285714);
  flex: 0 1 (100% / 1.714285714285714);
}
.col-8 {
  width: (100% / 1.5);
  flex: 0 1 (100% / 1.5);
}
.col-9 {
  width: (100% / 1.333333333333333);
  flex: 0 1 (100% / 1.333333333333333);
}
.col-10 {
  width: (100% / 1.2);
  flex: 0 1 (100% / 1.2);
}
.col-11 {
  width: (100% / 1.090909090909091);
  flex: 0 1 (100% / 1.090909090909091);
}
.col-12 {
  width: 100%;
  flex: 0 1 100%;
}

// @media (min-width: 1200px) {

@include res(lg-up) {
  .col-xl {
    flex: 1 0 0%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: calc(8.33333333% - 20px);
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: calc(16.66666667% - 20px);
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: calc(25% - 20px);
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: calc(33.33333333% - 20px);
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: calc(41.66666667% - 20px);
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: calc(50% - 20px);
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: calc(58.33333333% - 20px);
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: calc(66.66666667% - 20px);
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: calc(75% - 20px);
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: calc(83.33333333% - 20px);
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: calc(91.66666667% - 20px);
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: calc(100% - 20px);
  }
  .col-xl-99 {
    flex: 0 0 auto;
    width: calc(100% - 10px);
  }
}
