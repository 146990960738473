@charset "UTF-8";
/* _monitoring.scss */

//
//  실시간 모니터링
// --------------------------------------------------

// 차량 위치 full 콘텐츠영역
.monitoring-wrap {
  display: flex;
  flex-direction: column;

  .site-main {
    flex: 1;
  }
  .content,
  .content-inner,
  .page-container,
  .page-body {
    height: 100%;
  }
  .content-inner {
    min-height: unset;
  }
  .page-body {
    padding-bottom: 0;
  }
  .location-wrap {
    height: 100%;
  }
}

.location-area {
  display: flex;
  height: 100%;
}
// 차량 위치 > 좌측 영역
.location-info {
  position: absolute;
  left: 0;
  width: 440px;
  //height: 100%;
  background-color: $white;

  // 차량 상세정보 영역이 열렸을 때 클래스
  &.is-opened-detail {
    position: relative;

    .location-info__collapse {
      right: -462px;
    }
    .location-info__detail {
      display: block;
    }
  }
  // 좌측영역 축소할 때 클래스
  &.collapsed {
    width: 0;

    + .location-map {
      padding-left: 0;
    }

    .location-info__dashboard,
    .location-info__detail {
      width: 0;
      overflow: hidden;
    }
    .location-info__dashboard {
      padding: 0;
    }
    .location-info__collapse {
      right: -22px;

      &:before {
        transform: translateY(-50%) rotateZ(180deg);
        transform-origin: center center;
      }
    }
  }
}
// 차량 위치 좌측 - 차량 위치
.location-info__dashboard {
  width: 440px;
  height: 100%;
  padding: 0 30px;
  border-right: 1px solid $gray-lighter;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 164px;
    background-color: $primary;
  }

  // 차량위치 - 상단 타이틀+제어기능
  .title-area {
    display: flex;
    position: relative;
    padding-top: 24px;
    margin-bottom: 10px;

    .title {
      font-size: $font-size-lg;
      color: $white;
    }
    .control {
      display: flex;
      align-items: center;
      margin-left: auto;
      column-gap: 8px;

      .reload {
        display: flex;
        align-items: center;

        .time {
          color: $white;
        }
        .btn-reload {
          margin-left: 5px;
        }
      }
      .custom-select.nice-select.small {
        background-color: transparent;
        border: 0;
        padding-left: 0;
        padding-right: 15px;

        &:before {
          content: "";
          display: block;
          width: 10px;
          height: 5px;
          background: url("../images/icon/icon_arrow_dropdown.svg") center
            center no-repeat;
          background-size: 10px 5px;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
        &:after {
          display: none;
          border-color: $warning;
        }
        .current {
          color: $white;
        }
      }
    }
  }

  // 차량위치 - 검색영역
  .search-area {
    position: relative;
    padding: 24px 25px;
    background-color: $white;
    border-radius: $border-radius-base;
    box-shadow: 0px 3px 10px $shadow-black;

    // 차량위치 - 상단 건수
    .vehicle-counts {
      display: flex;

      .count {
        flex: 1 0 22.222%;
        text-align: center;

        strong {
          display: block;
          font-size: 22px;
        }
        .cate {
          font-size: $font-size-base;
        }
        &.total {
          color: $black;
        }
        &.operate {
          color: $primary;
        }
        &.error {
          color: $red;
        }
        &.end {
          color: $gray;
        }
      }
    }
    // 차량위치 - 검색바
    .vehicle-input {
      margin-top: 20px;

      .input-box {
        display: flex;
        background-color: $gray-lightest;
        border-radius: $border-radius-base;

        .form-control {
          border-color: $gray-lightest;
          background-color: $gray-lightest;
          border-radius: 0;
        }
        button {
          height: 48px;
          padding: 0 12px;

          img {
            width: 28px;
          }
        }
      }
    }
  }
  .list-area {
    padding-bottom: 30px;
    margin-top: 19px;
    border-radius: $border-radius-base;
    box-shadow: 0px 3px 10px $shadow-black;

    .list-area__body {
      padding-left: 20px;
      padding-right: 20px;
    }
    .filter {
      text-align: center;
      margin-bottom: 12px;

      .radio-group .radio-button {
        margin-bottom: 6px;
      }
    }
  }

  // 차량현황, 이상차량 공통
  .vehicle-branch-list {
    @include scrollbar();
    overflow-y: auto;
  }
  .vehicle-list {
    font-size: $font-size-base;
  }
  .vehicle-item {
    padding: 10px;
    border-bottom: 1px solid $gray-lighter;

    &.is-selected {
      background-color: $primary-bg;

      .vehicle-item__head .number {
        color: $primary;
      }
    }
  }
  .vehicle-item__head {
    display: flex;
    color: $black;
    margin-bottom: 6px;

    .number {
      flex: 1 1 auto;
      font-weight: $font-weight-medium;
    }
    .datetime {
      flex: 0 0 90px;
      margin-left: auto;
      color: #aaa;
      text-align: right;
    }
  }
  .vehicle-item__body {
    display: flex;
    flex-wrap: wrap;
    row-gap: 2px;

    dl {
      flex: 0 1 50%;
      display: flex;
    }
    dt {
      min-width: 63px;
    }
    dd {
      color: $gray;
    }
  }

  // 차량현황
  .vehicle-status {
    .vehicle-branch-list {
      max-height: 434px;
      max-height: calc(100vh - 494px);
    }
  }
  // 이상 차량
  .vehicle-problem {
    .vehicle-branch-list {
      max-height: 398px;
      max-height: calc(100vh - 530px);
    }
  }
}
// 차량 위치 좌측 - 차량 상세정보
.location-info__detail {
  width: 440px;
  background-color: $white;
  position: absolute;
  top: 0;
  left: 440px;
  z-index: 10;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: none;

  // 차량 상세정보 > 상단
  .detail-header {
    position: relative;
    box-shadow: 0px 3px 20px $shadow-black;
    padding: 22px 30px 0;

    .heading {
      margin-bottom: 15px;
    }
    .heading .title {
      display: flex;
      font-size: $font-size-lg;
    }
    .heading .btn-close {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 24px;
      height: 24px;
      background: url("../images/icon/icon_close_gray.svg") no-repeat;
      background-size: 24px 24px;
      background-position: center center;
    }
    .vehicle-info .number {
      font-size: 18px;
      font-weight: $font-weight-bold;
      color: $primary;
    }
    .vehicle-info .number .badge {
      margin-left: 4px;
    }
    .vehicle-info .location {
      margin-top: 9px;
      font-size: $font-size-base;
      color: $gray;

      &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 24px;
        height: 24px;
        background: url("../images/icon/icon_location.svg") no-repeat;
        background-size: 24px 24px;
        background-position: center center;
      }
    }
    .tabs button {
      min-width: 33.333%;
      border-bottom: 0;

      &.active:after {
        z-index: 10000;
      }
    }
  }
  // 차량 상세정보 > 본문
  .detail-body {
    @include scrollbar();
    height: calc(100vh - 284px);
    padding: 30px;
    overflow-y: auto;

    .list-card.no-outlined {
      padding: 0;
    }
    .list-card__head {
      + .list-card__head {
        margin-bottom: 13px;
      }

      .list-select {
        margin-left: 4px;

        &:first-child {
          margin-left: 0;
        }
      }
      .list-btns .btn {
        min-width: 78px;
      }
    }
    .list-card__footer .list-footer__btns .btn {
      padding-left: 24px;
      padding-right: 24px;
    }

    // 운행경로 탭
    #detailTab2 .list-card:first-child {
      position: relative;

      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: $gray-lighter;
        position: absolute;
        bottom: -24px;
      }
    }
    // 날짜, 시간 선택 폼
    .select-course {
      .pick-area {
        .radio-group.type2 {
          display: flex;
          flex-wrap: wrap;
          gap: 4px 3px;
        }
        .radio-group.type2 .radio-button {
          flex: 0 1 calc(20% - 3px);
          margin-left: 0;
        }
        .radio-group.type2 .radio-button__inner {
          width: 100%;
        }
      }
      .pick-area__title {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        font-size: $font-size-md;
        font-weight: $font-weight-bold;
        color: $black;

        .btn-pick-time {
          min-width: 74px;
          height: 36px;
          line-height: 36px;
          font-size: $font-size-base;
          font-weight: $font-weight-medium;
          margin-left: auto;
        }
      }
      .pick-daterange .pick-area__title {
        margin-bottom: 17px;
      }

      // 날짜 캘린더
      .embedded-daterangepicker {
        min-height: 374px;

        .daterangepicker {
          position: relative !important;
          top: auto !important;
          left: auto !important;
          float: left;
          width: 100%;
          margin-top: 0;
          margin-bottom: 20px;
          border: 0;
          box-shadow: 0px 0px 20px $shadow-black;
          border-radius: $border-radius-md;
        }
        .daterangepicker::before,
        .daterangepicker::after {
          display: none;
        }
        .daterangepicker .drp-calendar {
          width: 100%;
          max-width: 100%;
        }
        .daterangepicker .drp-calendar.left {
          padding: 30px 0 30px 30px;
        }
        .daterangepicker .drp-calendar.left .calendar-table {
          padding-right: 30px;
        }
        .daterangepicker .drp-calendar .prev,
        .daterangepicker .drp-calendar .next {
          color: $gray;
        }
        .daterangepicker .calendar-table .prev:hover,
        .daterangepicker .calendar-table .next:hover {
          background-color: transparent;
          border-color: transparent;
          color: inherit;
        }
        .daterangepicker .calendar-table .prev span,
        .daterangepicker .calendar-table .next span {
          color: $white;
          border: 0;
          border-radius: 0;
          display: inline-block;
          padding: 0;
          transform: rotate(0deg);
          -webkit-transform: rotate(0deg);

          &:before {
            font-size: $font-size-base;
            color: $gray;
            font-weight: $font-weight-regular;
          }
        }
        .daterangepicker .calendar-table .prev span:before {
          content: "이전달";
        }
        .daterangepicker .calendar-table .next span:before {
          content: "다음달";
        }
        .daterangepicker .drp-calendar .month .monthselect,
        .daterangepicker .drp-calendar .month .yearselect {
          font-size: $font-size-md;
        }
        .daterangepicker .drp-calendar .month .monthselect {
          transform: translateX(70px);
        }
        .daterangepicker .drp-calendar .month .yearselect {
          transform: translateX(-41px);
        }
        .daterangepicker .drp-buttons {
          border: 0;
          padding: 0;
        }
        .daterangepicker .drp-buttons .drp-selected,
        .daterangepicker .drp-buttons .cancelBtn {
          display: none;
        }
        .daterangepicker .drp-buttons .applyBtn {
          min-width: 74px;
          height: 36px;
          line-height: 36px;
          font-size: $font-size-base;
          font-weight: $font-weight-medium;
          @extend .btn-outline-primary-primary;
          position: absolute;
          top: -48px;
          right: 0;
        }
      }
    }
    // 날짜, 시간 선택된 정보
    .selected-course {
      dl {
        @include clearfix();
        margin-top: 8px;
        display: inline-flex;
        width: 100%;
        align-items: center;
        display: none;

        &.show {
          display: inline-flex;
        }
      }
      dt {
        min-width: 37px;
        margin-right: 8px;
        font-size: $font-size-md;
        font-weight: $font-weight-medium;
        color: $black;
      }
      dd {
        display: flex;
        align-items: center;
        width: calc(100% - 45px);
        font-size: $font-size-md;
        font-weight: $font-weight-medium;
        color: $gray;
        flex: 1;

        p {
          @include text-cut();
          display: block;
          width: calc(100% - 78px);
        }
        .btn {
          min-width: 78px;
          height: 36px;
          line-height: 36px;
          margin-left: 7px;
        }
      }
    }
  }
}
// 차량 위치 > 좌측 영역 닫기버튼
.location-info__collapse {
  position: absolute;
  top: 50vh;
  right: -22px;
  width: 22px;
  height: 70px;
  padding: 0;
  margin-top: -35px;
  background: $white;
  border-radius: 0px $border-radius-base $border-radius-base 0px;
  z-index: 10;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    width: 22px;
    height: 22px;
    vertical-align: middle;
    background: url("../images/icon/icon_chevron_left.svg") no-repeat;
    background-size: 22px 22px;
    background-position: center center;
  }
}
// 차량 위치 > 우측 - 지도 영역
.location-map {
  flex: 1 0 auto;
  padding-left: 440px;

  .map-wrap {
    .root_daum_roughmap {
      width: 100%;
      height: 100%;
    }
    .root_daum_roughmap .wrap_map {
      height: calc(100vh - 101px);
    }
    .root_daum_roughmap .wrap_controllers {
      display: none;
    }
  }
  .map-area {
    .wrap_map {
      width: 100%;
      height: 100vh;
    }
  }
}

@media only screen and (max-width: 900px) {
  .location-info {
    width: 384px;
  }
  .location-info__dashboard,
  .location-info__detail {
    width: 384px;
  }
  .location-info__detail {
    left: 384px;
  }
  .location-info.collapsed .location-info__collapse {
    right: -22px;
  }
  .location-info.is-opened-detail .location-info__collapse {
    right: -384px;
    box-shadow: 0 0 7px $shadow-black;
    border-radius: 4px 0px 0px 4px;
  }
  .location-info.is-opened-detail.collapsed .location-info__collapse {
    right: -22px;
  }
  .location-map {
    padding-left: 384px;
  }
  .location-info__dashboard .vehicle-problem .vehicle-branch-list {
    max-height: calc(100vh - 566px);
  }
  .location-info__detail .detail-body .select-course .embedded-daterangepicker {
    min-height: 382px;
  }
}
