@charset "UTF-8";
/* _layout.scss */

//
//  Skip Navigation
// --------------------------------------------------
.skipNav {
  position: absolute;
  left: -300%;
  font-size: 1em;
  color: $white;
  background: #555;

  @include hover-focus() {
    display: block;
    position: fixed;
    left: 0;
    z-index: 9999;
    width: 100%;
    padding: 15px 10px;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    line-height: 1.5;
    text-align: center;
    vertical-align: middle;
    color: $white;
    text-decoration: none;
  }
}

//
//  Container
// --------------------------------------------------

// main
.site-main {
  position: relative;
  width: 100%;
  transition: padding-top 250ms ease-in-out;

  // 메인페이지
  &.index {
    .content {
      max-width: none;
      padding-left: 0;
      padding-right: 0;
    }
    .content-inner {
      padding: 0;
    }
  }

  // 로그인 (단독 페이지)
  &.single {
    .content {
      padding-top: 189px;
    }
  }
}

.container {
  position: relative;
  width: 100%;
}

//
//  Contents Layout
// --------------------------------------------------

// 컨텐츠 - wrap
.content {
  @include prefix(transition, padding 250ms ease, webkit o);
  width: 100%;
  padding-top: 50px;
  margin: 0 auto;
}

// 컨텐츠 - 실 컨텐츠 영역
.content-inner {
  width: 100%;
  min-height: calc(698px - 3.75rem);
}

//
//  page Layout
// --------------------------------------------------

.page-header {
  display: flex;
  align-items: baseline;
  padding: 40px 70px 0;
  box-shadow: 0px 3px 10px $shadow-black;
}

.page-title {
  line-height: 1.5;
  font-size: $font-size-xxl;
  font-weight: $font-weight-bold;
  color: $black;
}

.page-body {
  position: relative;
  padding-bottom: 110px;
}

//
//  List Layout
// --------------------------------------------------

// 리스트 - 상단
.list-header {
  margin-bottom: 20px;
  box-shadow: 0px 3px 10px $shadow-black;

  &.card {
    padding: 40px 70px;
    background-color: $white;
    border: 0;
    border-radius: 0;
  }

  &.view-header {
    padding: 60px 70px;

    .page-title {
      font-size: $font-size-xxl;
      text-align: center;
    }
  }

  .page-title {
    line-height: 1.5;
    font-size: $font-size-xl;
    font-weight: $font-weight-medium;
    color: $black;
  }
}

// 리스트 - 상단 검색단
.list-search {
  padding-top: 16px;

  .list-search__filter {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 22px;

    .form-item-radio {
      margin-right: 20px;
    }
  }

  .form-search {
    display: flex;
    width: 100%;
    align-items: flex-end;
  }
  .form-search-inputs {
    flex: 1;

    .form-label {
      font-size: $font-size-md;
      font-weight: $font-weight-medium;
      margin-bottom: 12px;
    }
    .form-control {
      background-color: $gray-lightest;
      border-color: $gray-lightest;
    }
    .form-control.form-datepicker {
      background: $gray-lightest url("../images/icon/icon_calendar.svg")
        calc(100% - 14px) center no-repeat;
    }
    .nice-select {
      width: 100%;
    }
  }
  .form-search-submit {
    width: 215px;
    margin-left: 65px;
  }

  // 팝업 내 상단 검색바
  &.simple-type {
    margin-bottom: 6px;

    .form-label {
      margin-bottom: 0;
      font-size: $font-size-base;
      font-weight: $font-weight-medium;
    }

    .form-search-inputs {
      .form-item-wrap {
        flex: 1;
      }
      .form-label {
        margin-bottom: 0;
        font-size: $font-size-base;
        font-weight: $font-weight-medium;
      }
    }
    .form-search-submit {
      width: 74px;
      margin-left: 19px;
    }
  }

  // 게시판 리스트 상단 검색바
  &.board-list-search {
    margin: 0 auto;

    .form-control {
      width: 400px;
    }
    .custom-select {
      width: 175px;
    }
    .btn {
      width: 140px;

      .ico {
        width: 18px;
      }
    }

    @include res(lg-down) {
      width: 100%;

      .form-group.is-row .form-item-wrap:nth-child(2) {
        flex: 1 0 auto;
      }
      .form-control {
        width: 100%;
      }
    }
  }

  @media (max-width: 1200px) {
    .form-search {
      display: block;
    }
    .form-search-submit {
      margin-top: 10px;
      margin-left: auto;
    }
  }
}

// 리스트 - 내용
.list-body {
  padding: 40px 70px 0;

  &.card {
    padding-bottom: 10px;
    background-color: $white;
    border-radius: $border-radius-sm;
  }
  &.cs-center {
    max-width: 1080px;
    width: 100%;
    margin: 0 auto;
    padding: 0 25px;
  }

  @include res(lg-down) {
    padding: 20px 20px 0;
  }
}
// 리스트 - 내용 영역에 list-card 상위묶음
.list-area {
  + .list-area {
    margin-top: 40px;
  }
}
.list-area__head {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .subject {
    font-size: $font-size-lg;
  }
  .filter {
    margin-left: 14px;
  }
}
.list-area__footer {
  margin-top: 15px;

  .btn {
    min-width: 128px;
  }

  &.align-right {
    text-align: right;
  }
}

// 리스트 - 내용 카드영역
.list-card {
  &.map-card {
    padding: 0;
  }
  &.no-outlined {
    border: 0;
    padding: 16px 0;
  }
}
// 리스트 - 내용 카드영역 상단 컨트롤박스
.list-card__head {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;

  &.bdb-1 {
    padding-bottom: 20px;
    border-bottom: 1px solid $gray-lighter;

    .list-subject {
      display: flex;
      align-items: center;
    }
    .list-btns {
      .btn {
        min-width: 75px;
      }
    }
  }
  + .list-card__head {
    margin-top: -10px;
  }

  // 좌측 제목
  .list-subject {
    font-size: $font-size-lg;
    color: $black;

    .required {
      font-size: $font-size-base;
      margin-left: 8px;
    }

    &.md {
      font-size: $font-size-md;
    }
  }
  // 좌측 건수
  .list-count {
    display: flex;
    align-items: center;

    .total {
      flex: 1 0 auto;
      font-size: $font-size-md;
      color: $black;

      em {
        color: $primary;
        font-weight: $font-weight-bold;
      }
    }
    .selected {
      display: inline-flex;
      align-items: center;
      padding: 3px 12px;
      margin-left: 12px;
      color: $primary;
      background-color: $primary-bg;
      border-radius: $border-radius-round;
      font-size: $font-size-base;

      .btn {
        padding: 0;
        width: 26px;
        height: 26px;
      }
    }
  }
  // 좌측 건수
  .list-category-filter {
    display: flex;
    align-items: center;

    > div {
      &:before {
        content: "";
        display: inline-block;
        width: 1px;
        height: 12px;
        margin: 0 10px;
        vertical-align: middle;
        background-color: $gray;
      }

      &:first-child:before {
        display: none;
      }

      flex: 1 0 auto;
      font-size: $font-size-md;
      color: $black;

      em {
        font-weight: $font-weight-bold;
      }
    }

    .total,
    .success {
      em {
        color: $primary;
      }
    }
    .error {
      em {
        color: $red;
      }
    }
  }
  // 우측 버튼모음
  .list-btns {
    margin-left: auto;

    &.align-left {
      margin-left: 0;
    }

    .btn {
      min-width: 100px;

      + .btn {
        margin-left: 8px;
      }
    }
  }
  .control.right {
    margin-left: auto;
  }
}
// 리스트 - 내용 카드영역 하단
.list-card__footer {
  position: relative;
  margin-top: 20px;
  // text-align: right;

  &.is-flex {
    display: flex;
    align-items: center;
  }

  // 하단 좌측 몇개씩보기
  .list-viewer {
    .select.form-control {
      width: 125px;
    }
  }

  // 리스트 - 하단 페이징
  .list-pager {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;

    &.align-left {
      margin-left: 0;
    }
  }

  // 리스트 - 하단 버튼
  .list-footer__btns {
    &.align-left {
      margin-left: 0;
    }
    &.align-center {
      text-align: center;
    }
    &.align-right {
      margin-left: auto;
    }
    .btn {
      min-width: 100px;
    }
    .btn + .btn {
      margin-left: 4px;
    }
  }
}

//
//  board 상세페이지
// --------------------------------------------------

// 1:1 민원 상세페이지
.board-view {
  // board-view__control (상단 관리 버튼)
  &__control {
    padding-bottom: 18px;
    border-bottom: 1px solid $gray-lightest;

    .btn-back {
      font-weight: $font-weight-bold;

      &:before {
        content: "";
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-right: 4px;
        background: url("../images/icon/icon_arrow_left_black.svg") center
          center no-repeat;
        background-size: 24px 24px;
        vertical-align: middle;
      }
    }
  }

  // board-view__head
  &__head {
    font-size: $font-size-base;

    .title,
    .etc {
      display: flex;
      padding: 15px 20px;
      color: $black;

      em {
        margin-right: 8px;
        color: $gray;
      }
    }
    // 제목
    .title {
      background-color: $gray-lightest;

      .status {
        margin-left: auto;
      }
    }
    // 제목 외 정보
    .etc {
      border-bottom: 1px solid $gray-lighter;

      .item {
        margin-left: 40px;

        &:first-child {
          margin-left: 0;
        }
        &.align-right {
          margin-left: auto;
        }
      }
    }
  }

  // board-view__body
  &__body {
    min-height: 300px;
    padding: 20px;
    border-bottom: 1px solid $gray-lighter;

    > * {
      line-height: 1.42;
      font-size: $font-size-base;
    }
  }

  // board-view__reply (문의답변)
  &__reply {
    .board-view__head {
      border-top: 0;
      border-bottom: 0;

      .title {
        background-color: $primary-bg;
        color: $primary;

        em {
          color: $primary;
        }
      }
    }
  }
}

//
//  Footer Style
// --------------------------------------------------
.site-footer {
  padding: 60px 0;
  font-size: $font-size-base;
  text-align: center;

  .tel {
    color: $primary;
  }
  .copyright {
    margin-top: 12px;
    color: $gray;
  }
}
