@charset "UTF-8";
/* _settings.scss */

//
//  관리설정
// --------------------------------------------------

// 메뉴그룹 관리
.mgnt-menuGroup {
  .set-menu {
    margin-top: 20px;

    > p {
      margin-bottom: 16px;
      font-size: $font-size-base;
      font-weight: $font-weight-medium;
    }
    .row {
      column-gap: 20px;
    }
  }
}

// 지점관리 > 맵 영역
.map-wrap {
  .root_daum_roughmap {
    width: 100%;
    height: 100%;
  }
  .root_daum_roughmap .wrap_map {
    height: 100%;
  }
  .root_daum_roughmap .wrap_controllers {
    display: none;
  }
}
.map-area {
  &#branchMap {
    height: 666px;
  }
}

// 에코 관리
.mgnt-ecoGrade {
  .list-card.no-outlined {
    padding: 32px 0;
    margin-top: 0;
    border-radius: 0;
    border-top: 1px solid $gray-lighter;

    &:first-child {
      border-top: 0;

      .form-label {
        width: 150px;
        flex: 1 0 auto;
      }
    }
    &:nth-child(2),
    &:last-child {
      .form-label {
        width: 95px;
        flex: 1 0 auto;
      }
    }
  }
}

// 점검운영항목 그래프 표현
.insna-type-bg {
  height: 20px;
  background: #F8F8F8 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
}
.insna-type1 {
  height: 20px;
  background: #DDDDDD 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
}

.insna-type2 {
  height: 20px;
  background: #1782FF 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
}

.insna-type3 {
  height: 20px;
  background: #ED3232  0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
}

.ft-color {
  color:#1782ff;
}

.bt-line {
  border-bottom: 1px solid #eaeaea;
}

.tb-custom-cell {
  max-width:250px;
}

.txt-area-bg {
  background-color: #f8f8f8;
  border-color: #f8f8f8;
  width:100% !important;;
}

