@charset "UTF-8";
/* _pagination.scss */

.pagination {
  display: flex;
  align-items: center;
  font-size: $font-size-md;
  color: $gray;
}
.paging-number {
  display: flex;
  align-items: center;

  .current {
    input {
      width: 58px;
      text-align: right;
    }
  }
  .total {
    margin-left: 8px;
    color: $gray;
  }
}
.paging-control {
  display: flex;
  align-items: center;
  margin-left: 20px;

  .page-btn {
    display: block;
    width: 40px;
    height: 40px;
    margin: 0;
    text-indent: -9999px;
    overflow: hidden;
    border: 1px solid $gray-lighter;

    &:first-child {
      border-radius: $border-radius-base 0 0 $border-radius-base;
    }
    &:last-child {
      border-radius: 0 $border-radius-base $border-radius-base 0;
      margin-left: -1px;
    }
  }
}
.page-btn-prev {
  background: $white
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='icon_left_black' width='24' height='24' viewBox='0 0 24 24'%3E%3Crect width='24' height='24' fill='none'/%3E%3Cpath d='M-185.222,2229.206l-4.723,4.723-4.723-4.723' transform='translate(2243.429 202.167) rotate(90)' fill='none' stroke='%23999999' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3C/svg%3E")
    center center no-repeat;
}
.page-btn-next {
  background: $white
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='icon_right_black' width='24' height='24' viewBox='0 0 24 24'%3E%3Crect width='24' height='24' fill='none'/%3E%3Cpath d='M9.445,4.723,4.723,0,0,4.723' transform='translate(14.223 7.5) rotate(90)' fill='none' stroke='%23999999' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3C/svg%3E")
    center center no-repeat;
}
