@charset "UTF-8";
/* _dashboard.scss */

//
//  대시보드
// --------------------------------------------------

.dashboard-bg {
  background-color: #F8F8F8 !important;
}
.fs-20 {
  font-size:20px !important;
}
.gp-group {
  display: flex;
}
.gp {
  width:32%;
  height:260px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}
.gp-right {
  margin-right:1%;
}
.sm-gp-group {
  display:flex;height:165px;background-color: #fff;
}
.sm-gp {
  width:25%;margin-right:0.5%;
}
.sm-gp-lo {
  height:160px;border:1px solid #EAEAEA;border-radius: 4px;
  padding: 30px;
}

.sm-gp-ft {
  font-size: 18px;font-weight: bold;margin-top:10px;
}
.sm-gp-value {
  font-size: 24px;color:#1782FF;font-weight: bold;
}
.sm-gp-tit {
  font-size: 16px;
}
.sm-gp-tit2 {
  font-size: 24px;
}