@charset "UTF-8";
/* _mixins.scss */

// breakpoint media queries
@mixin res($key, $map: $--breakpoints) {
  // 循环断点Map，如果存在则返回
  @if map-has-key($map, $key) {
    @media only screen and #{inspect(map-get($map, $key))} {
      @content;
    }
  } @else {
    @warn "Undefeined points: `#{$map}`";
  }
}

/// Mixin to prefix a property
@mixin prefix($property, $value, $prefixes: ()) {
  @each $prefix in $prefixes {
    #{'-' + $prefix + '-' + $property}: $value;
  }
  // Output standard non-prefixed declaration
  #{$property}: $value;
}

// Mixin to animation keyframes
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

// Mixin to box-sizing
@mixin boxSizing {
  box-sizing: border-box;
}

// Mixin to clear both
@mixin clearfix {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

// Mixin to hover focus active Style
@mixin hover() {
  &:hover {
    @content;
  }
}

@mixin hover-focus() {
  &:hover,
  &:focus {
    @content;
  }
}

@mixin plain-hover-focus() {
  &,
  &:hover,
  &:focus {
    @content;
  }
}

@mixin hover-focus-active() {
  &:hover,
  &:focus,
  &:active {
    @content;
  }
}

// Mixin to Placeholder text
@mixin placeholder-color($color: $gray) {
  // Firefox
  &::-moz-placeholder {
    color: $color;
    opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
  }
  &:-ms-input-placeholder {
    color: $color !important;
  } // Internet Explorer 10+
  &::-ms-input-placeholder {
    color: $color !important;
  } // Edge (also supports ::-webkit-input-placeholder)
  &::-webkit-input-placeholder {
    color: $color;
  } // Safari and Chrome
}

// Mixin to scrollbar
@mixin scrollbar() {
  // Chrome, Safari용 스크롤 바
  &::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #bcbcbc;
    border: 1px solid #bcbcbc;
    border-radius: $border-radius-round;
  }
}

// Mixin to cut texts (1 line)
@mixin text-cut {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Mixin to cut texts (multi line)
@mixin text-cut-multiline($height: 3em, $line: 2) {
  display: -webkit-box;
  max-height: $height;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-wrap: break-word;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin bar-before {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    width: 1px;
    height: 16px;
    background-color: $gray-lighter;
  }
}
