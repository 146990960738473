@charset "UTF-8";
/* _terms.scss */

//
//  약관
// --------------------------------------------------
.terms-pc {
  padding-left:60px;width:95%;
}
.terms-pc2 {
  padding-left:60px;width:90%;
}

.terms-pd-zero {
  padding: 0 0 0 0;
}

.terms-textarea {
  height:250px;overflow-x: auto;
}

.terms-tab-lst {
  padding-top:3px;border-top:solid #ededed 1px;
}

.terms-wd100 {
  width:100%;
}

.terms-max-wd {
  max-width:none;
}