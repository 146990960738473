@charset "UTF-8";
/* _member.scss */

//
//  로그인, 아이디&비번찾기
// --------------------------------------------------

.member-wrap {
  .content {
    padding-top: 0;
  }
}

.member-container {
  width: 100%;
  max-width: 520px;
  margin: 0 auto;

  .card {
    padding: 60px;
    border: 0;
    background-color: $white;
    box-shadow: 0px 0px 10px $shadow-black;
    border-radius: $border-radius-md;
  }
  .card-header {
    margin-bottom: 40px;
    line-height: 1.375;
    letter-spacing: -0.05em;
    font-size: $font-size-xxl;
    text-align: center;
  }
  .card-title {
    img {
      width: 220px;
      height: 42px;
    }
  }

  .form-group.is-col .form-item-wrap {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .form-error-check {
    margin-top: 20px;
  }
  .btn-group {
    margin-top: 40px;

    .btn + .btn {
      margin-top: 24px;
    }
  }

  // 아이디 & 비밀번호 찾기 버튼
  .login-user-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  // 아이디 & 비밀번호 찾기
  .find-box {
    box-shadow: 0px 0px 10px $shadow-black;
    border-radius: $border-radius-md;
    overflow: hidden;

    .card {
      box-shadow: none;
    }
    .form-item {
      .form-item-inn {
        .btn {
          min-width: 128px;
        }
      }
    }
  }
}
