@charset "UTF-8";
/* _button.scss */

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: $font-weight-regular;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 40px;
  line-height: 40px;
  padding: 0 9px;
  border-radius: $border-radius-base;
  font-size: $font-size-base;

  .ico {
    width: 24px;
  }
  .txt + .ico {
    margin-left: 5px;
  }
}

// button sizes
.btn {
  &-xsmall {
    height: 24px;
    line-height: 24px;
  }
  &-small {
    height: 32px;
    line-height: 32px;
  }
  &-large {
    height: 48px;
    line-height: 48px;
  }
  &-xlarge {
    height: 66px;
    line-height: 64px;
    font-size: 20px;
    font-weight: 700;

    .ico {
      width: 24px;
    }
  }
}

// button colors
.btn-fill-primary-black {
  color: $black;
  background-color: $primary;
  border-color: $primary;
}
.btn-fill-primary-white {
  color: $white;
  background-color: $primary;
  border-color: $primary;

  &:disabled {
    background-color: $gray-lighter;
    border-color: $gray-lighter;
    color: $gray;
  }
}

.btn-fill-primaryLight-primary {
  color: $primary;
  background-color: $primary-bg;
  border-color: $primary-bg;
}
.btn-outline-primary-primary {
  color: $primary;
  background-color: $white;
  border-color: $primary;
}
.btn-outline-gray-gray {
  color: $gray;
  background-color: $white;
  border: 1px solid $gray-lighter;
}
.btn-link {
  height: initial;
  line-height: inherit;
  padding: 0;
  color: $black;

  &.underline {
    text-decoration: underline;
  }
}

// button width 100%
.btn-block {
  width: 100%;
}

// button group in the bottom
.btn-group-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  .btn {
    min-width: 74px;
  }
  .btn + .btn {
    margin-left: 14px;
  }
}
